import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

export type ExpertContactModal = {
	open: boolean
	step: 'FORM' | 'THANK_YOU'
	leadSource?: string
	defaultFormType?: 'hvac' | 'pv'
}

const defaults: ExpertContactModal = {
	open: false,
	step: 'FORM'
}

const contextName = 'EXPERT_CONTACT_MODAL_CTX'

export function setExpertContactModalState(
	data?: ExpertContactModal
): Writable<ExpertContactModal> {
	const state = writable<ExpertContactModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getExpertContactModalState(): Writable<ExpertContactModal> {
	return getContext<Writable<ExpertContactModal>>(contextName)
}
